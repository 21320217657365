document.addEventListener("DOMContentLoaded", () => {
  // Add 'brinca-brinca' class to icons
  document.querySelectorAll(".location i").forEach((icon) => {
    icon.classList.add("brinca-brinca");
  });

  // Set body visibility and fade effect
  document.body.style.visibility = "visible";
  document.body.classList.add("fadeTofade");

  // Load header content
  fetch("header.html")
    .then((response) => response.text())
    .then((html) => {
      document.querySelector("header").innerHTML = html;

      initNavigationEvents();
      agregarClaseNavActivo();
      logoClickeable();
      setDataUrlAttributes();

      document
        .querySelector(".opn-cls-orders")
        ?.addEventListener("click", (event) => {
          event.stopPropagation();
          document.querySelector(".nav-box")?.classList.toggle("expande");
          document
            .querySelector(".opn-cls-orders i")
            ?.classList.toggle("rotate-it");
        });

      document.addEventListener("click", (event) => {
        if (
          !event.target.closest(".nav-box") &&
          !event.target.closest(".opn-cls-orders")
        ) {
          document.querySelector(".nav-box")?.classList.remove("expande");
          document
            .querySelector(".opn-cls-orders i")
            ?.classList.remove("rotate-it");
        }
      });

      document.querySelector(".opn-cls")?.addEventListener("click", () => {
        document.querySelector(".wrap_links")?.classList.toggle("open--cls");
        document.querySelector(".opn-cls span")?.classList.toggle("equis");
      });

      document.addEventListener("click", (event) => {
        if (
          !event.target.closest("nav") &&
          document.querySelector(".wrap_links")?.classList.contains("open--cls")
        ) {
          document.querySelector(".wrap_links")?.classList.remove("open--cls");
          document.querySelector(".opn-cls span")?.classList.remove("equis");
        }
      });
    });

  // Load footer content
  fetch("footer.html")
    .then((response) => response.text())
    .then((html) => {
      document.querySelector("footer").innerHTML = html;

      // Update year dynamically
      document.querySelectorAll(".year").forEach((yearEl) => {
        yearEl.textContent = new Date().getFullYear();
      });

      // Agency click handler
      document.querySelector(".agency")?.addEventListener("click", () => {
        window.open("https://restaurantly.io/", "_blank");
      });

      initNavigationEvents();
    });

  // Navigation events
  const domain = "https://www.soldejalisco.net/";
  const pages = [
    { page: "home", file: domain },
    { page: "about", file: `${domain}about` },
    { page: "menu", file: `${domain}menu` },
    { page: "specials", file: `${domain}specials` },
    { page: "catering", file: `${domain}catering` },
    { page: "parties", file: `${domain}parties` },
    { page: "jobs", file: `${domain}jobs` },
    { page: "accessibility", file: `${domain}accessibility` },
  ];

  // Function to set data-url attribute
  const setDataUrlAttributes = () => {
    pages.forEach(({ page, file }) => {
      document.querySelectorAll(`.${page}`).forEach((link) => {
        link.setAttribute("data-url", file); // Add the data-url attribute dynamically
        link.setAttribute("href", file); // Add the href attribute dynamically for sitemap recognition
      });
    });
  };

  const initNavigationEvents = () => {
    pages.forEach(({ page, file }) => {
      document.querySelectorAll(`.${page}`).forEach((link) => {
        link.addEventListener("click", () => {
          window.location.href = file;
        });
      });
    });
  };

  const agregarClaseNavActivo = () => {
    const currentPath = window.location.pathname.trim().toLowerCase();
    document.querySelectorAll(".page").forEach((page) => {
      const linkClass = [...page.classList].find((cls) => cls !== "page");
      if (linkClass && currentPath.includes(linkClass)) {
        page.classList.add("nav_activo");
      }
    });

    if (currentPath.endsWith("/")) {
      document.querySelector(".home")?.classList.add("nav_activo");
    }
  };

  const logoClickeable = () => {
    document
      .querySelector("header picture img")
      ?.addEventListener("click", () => {
        window.location.href = domain;
      });
  };

  const date_posts = () => {
    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = new Date().getDay();

    const imagesByDay = [
      "domingo.webp",
      "lunes.webp",
      "martes.webp",
      "miercoles.webp",
      "jueves.webp",
      "viernes.webp",
      "sabado.webp",
    ];

    const imagePath = "assets/img/";

    // Function to display the image corresponding to the day of the week
    const displayImageByDay = (day) => {
      const fullPath = `${imagePath}${imagesByDay[day]}`;

      // Check if the file exists
      fetch(fullPath, { method: "HEAD" })
        .then((response) => {
          if (response.ok) {
            // Update the background image
            document.querySelector(
              ".modal-fondo"
            ).style.backgroundImage = `url(${fullPath})`;
          } else {
            console.warn(`Image not found: ${fullPath}`);
          }
        })
        .catch((error) => {
          console.error("Error checking file existence:", error);
        });
    };

    // Display the image for the current day
    displayImageByDay(dayOfWeek);
  };

  const data_scroll_to = () => {
    // Attach click event to all buttons with the "data-scroll-to" attribute
    document.querySelectorAll("[data-scroll-to]").forEach((button) => {
      button.addEventListener("click", () => {
        const targetSelector = button.getAttribute("data-scroll-to");
        const popup = document.getElementById("popup");

        if (targetSelector) {
          const targetElement = document.querySelector(targetSelector);
          if (targetElement) {
            // Scroll smoothly to the target element
            targetElement.scrollIntoView({ behavior: "smooth" });

            // Close the modal if it exists
            if (popup) {
              popup.classList.remove("visible");
              popup.classList.add("hidden");
            }
          } else {
            console.error(
              `Target element not found for selector: ${targetSelector}`
            );
          }
        } else {
          console.error("data-scroll-to attribute not found on button.");
        }
      });
    });
  };

  const abre_modal = () => {
    // Verifica si el usuario está en el index.html o en la raíz del sitio
    const isIndexPage =
      window.location.pathname === "/" ||
      window.location.pathname.endsWith("index.html");

    if (!isIndexPage) return; // Si no está en el index, no ejecuta nada

    const popup = document.getElementById("popup");
    const closePopup = document.getElementById("closePopup");

    if (popup && closePopup) {
      // Mostrar el modal después de 1.5 segundos
      setTimeout(() => {
        popup.classList.remove("hidden");
        popup.classList.add("visible");

        // Llama a las funciones dentro del modal
        // date_posts(); Si no necesitas esta función, elimínala
        data_scroll_to();
      }, 1500);

      closePopup.addEventListener("click", () => {
        // Inicia la animación de cierre
        popup.classList.remove("visible");
        popup.classList.add("closing"); // Agregar clase para animación de cierre
        setTimeout(() => {
          popup.classList.add("hidden"); // Agrega la clase hidden después de la animación
          popup.classList.remove("closing");
        }, 300); // Ajusta este tiempo a la duración de tu animación CSS
      });
    } else {
      console.error("Popup o botón de cierre no encontrados en el DOM.");
    }
  };

  // Función para verificar el scroll y activar el modal cuando se llegue al 30% de la página
  const verificarScroll = () => {
    const alturaPagina = document.documentElement.scrollHeight; // Altura total de la página
    const scrollPos = window.scrollY + window.innerHeight; // Posición del scroll + altura de la ventana

    // Verifica si el usuario ha desplazado al menos el 10% de la página
    if (scrollPos >= alturaPagina * 0.1) {
      abre_modal();
      // Elimina el listener de scroll después de que se haya mostrado el modal
      window.removeEventListener("scroll", verificarScroll);
    }
  };

  // Agrega un event listener para el scroll
  window.addEventListener("scroll", verificarScroll);

  // Sticky header and scroll-to-top button
  window.addEventListener("scroll", () => {
    if (window.scrollY > 150) {
      document.querySelector("header")?.classList.add("sticky_header");
      document.querySelector(".up")?.classList.add("show_btn");
      document.querySelector(".opn-cls span")?.classList.add("achica");
    } else {
      document.querySelector("header")?.classList.remove("sticky_header");
      document.querySelector(".up")?.classList.remove("show_btn");
      document.querySelector(".opn-cls span")?.classList.remove("achica");
    }
  });

  document.querySelector(".up")?.addEventListener("click", () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  // Menu tabs
  const menuTabs = () => {
    const tabs = document.querySelectorAll(".menu_tabs");
    const menuDivs = document.querySelectorAll(".mm");
    menuDivs.forEach((div, index) => {
      div.style.display = index === 0 ? "block" : "none";
    });
    tabs.forEach((tab, index) => {
      tab.classList.toggle("colorea", index === 0);
      tab.addEventListener("click", () => {
        menuDivs.forEach((div) => (div.style.display = "none"));
        menuDivs[index].style.display = "block";
        tabs.forEach((tab) => tab.classList.remove("colorea"));
        tab.classList.add("colorea");
      });
    });
  };
  menuTabs();

  // Cache-busting for assets
  ["img", "script[src]", "video source"].forEach((selector) => {
    document.querySelectorAll(selector).forEach((el) => {
      const attr = "src";
      const url = el.getAttribute(attr);

      // Para otros elementos (img, script, video)
      if (url) {
        el.setAttribute(attr, `${url}?v=${Date.now()}`);
      }
    });
  });

  // Load videos after updating src
  document.querySelectorAll("video").forEach((video) => video.load());

  // Selecciona todas las figuras que contienen el atributo data-bg
  const lazyLoadFigures = document.querySelectorAll("figure[data-bg]");

  // Configuración del IntersectionObserver
  const observer = new IntersectionObserver(
    (entries, observerInstance) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const figure = entry.target;
          const bgImage = figure.dataset.bg; // Usa dataset para acceder al atributo

          if (bgImage) {
            figure.style.backgroundImage = `url(${bgImage})`;
            figure.classList.add("loaded"); // Añade una clase si es necesario
            observerInstance.unobserve(figure); // Deja de observar la figura
          }
        }
      });
    },
    { threshold: 0.1 } // Opcional: carga cuando el 10% del elemento sea visible
  );

  // Observa cada figura que tiene un data-bg
  lazyLoadFigures.forEach((figure) => observer.observe(figure));

  // Dynamic descriptions
  const DESC01 = "order your favorite dishes in seconds!";
  const DESC02 = "subscribe to <br> our vip club!";
  document.querySelectorAll(".description-01").forEach((el) => {
    el.innerHTML = DESC01;
  });
  document.querySelectorAll(".description-02").forEach((el) => {
    el.innerHTML = DESC02;
  });
});
